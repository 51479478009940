<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="#">{{ $t("management") }} {{ $t("projects") }}</a>
        </li>
        <li>-</li>
        <li>
          <a class="active" href="#">{{ $t("report") }} {{ $t("cost") }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("projects") }}</span>
        <v-select multiple :options="projects" label="name" v-model="project"
          style="background-color: white; color: black; width: 100%">
          <template #option="option">
            <div style="text-align: center">
              <span style="color: black">{{ option.name }}</span>
            </div>
          </template> </v-select>
        <button @click.prevent="allprojects">كل المشروعات</button>
        <br />
        <span>{{ $t("from") }}</span>
        <input type="date" :placeholder="$t('from')" v-model="from" />
        <span>{{ $t("to") }}</span>
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
      </div>
      <div class="head2">
        <button class="btn btn-primary" v-if="fromdate && todate" type="submit" @click="pdf">
          <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
        </button>
        <button type="submit" v-if="fromdate && todate" @click="exportToExcel">
          <i class="bi bi-file-earmark-excel"></i> Excel
        </button>
      </div>
    </div>
    <div class="body" id="main" v-if="fromdate && todate">
      <br />
      <div :style="{ direction: css.EN }">
        <h3>{{ $t("cost") }} {{ $t("projects") }}</h3>
        <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
        <table class="table">
          <thead style="position: static">
            <tr>
              <th>{{ $t("project") }}</th>
              <th>{{ $t("owner") }}</th>
              <th>{{ $t("value") }} {{ $t("land") }}</th>
              <th>تكلفة التطوير دون الضريبة</th>
              <th>تكلفة التطوير شاملة الضريبة</th>
              <th>عمولة ادارة المشروعات</th>
              <th>تكلفة الارض و البناء بدون الضريبة</th>
              <th>المساحة الاجمالية</th>
              <th>صافى المساحة</th>
              <th>سعر متر المباني</th>
              <th>سعر متر قائم</th>
              <th>سعر المتر شامل العمولة</th>
              <!-- <th>سعر المتر قائم</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="x in data1" :key="x">
              <td>
                <router-link :to="'/property/RevenuesReportDetails/' +
                  x.actnum +
                  '/' +
                  fromdate +
                  '/' +
                  todate
                  ">{{ x.name }}
                </router-link>
              </td>
              <td>{{ x.ownername }}</td>
              <td>{{ x.landvalue }}</td>
              <td>{{ x.amount }}</td>
              <td>{{ x.totalamount }}</td>
              <td>{{ x.commission }}</td>
              <td>{{ Number(x.landvalue) + Number(x.amount) }}</td>
              <td>{{ x.landspace }}</td>
              <td>{{ x.buildspace }}</td>
              <td>{{ Number(x.costm).toFixed(2) }}</td>
              <td>{{ Number(x.costm2).toFixed(2) }}</td>
              <td>{{ Number(x.costmtax).toFixed(2) }}</td>
              <!-- <td>{{ Number(((Number(x.commission) + Number(x.landvalue) + Number(x.amount)) / x.rateofreturn) /
                x.landspace).toFixed(2) }}
              </td> -->
              <!-- {{ Number((Number(x.landvalue) + Number(x.amount) + Number(x.commission)) / x.landspace).toFixed(2) }} -->
            </tr>
          </tbody>
          <tfoot v-if="totalamount > 0">
            <tr>
              <td colspan="3">{{ $t("total") }}</td>
              <td>{{ Number(amount).toLocaleString() }}</td>
              <td>{{ Number(totalamount).toLocaleString() }}</td>
              <td>{{ Number(commission).toLocaleString() }}</td>

            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="buttom" v-if="fromdate && todate">

    </div>
  </div>
</template>
<script>
import axios from "axios";
import html2pdf from "html2pdf.js";
import vSelect from "vue-select";
import * as XLSX from "xlsx";
export default {
  async mounted() {
    const result = await axios.get("/data/allprojects");
    if (result.status == 200) {
      this.projects = result.data.data;
    }
  },
  props: ["css"],
  components: {
    vSelect,
  },
  data() {
    return {
      fromdate: "",
      todate: "",
      data1: [],
      from: "",
      to: "",
      projects: "",
      project: "",
      amount: "",
      totalamount: "",
      commission: ""
    };
  },
  methods: {
    exportToExcel() {
      var data = [];
      for (let i = 0; i < this.data1.length; i++) {
        const name = this.data1[i];
        data.push({
          الفترة_من: this.fromdate,
          الفترة_الى: this.todate,
          اسم_المالك: name.ownername,
          قيمة_الارض: name.landvalue,
          تكلفة_التطوير_دون_الضريبة: name.amount,
          تكلفة_التطوير_شاملة_الضريبة: name.totalamount,
          عمولة_ادارة_المشروع: name.commission,
          تكلفة_الارض_والبناء_بدون_الضريبة: Number(name.landvalue) + Number(name.amount),
          المساحة_الاجمالية: name.landspace,
          صافى_المساحة: name.buildspace,
          سعر_متر_المباني: (name.costm).toFixed(2),
          سعر_المتر_شامل_العمولة: (name.costmtax).toFixed(2),

        });
      }
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate a download link to the Excel file
      XLSX.writeFile(wb, `تكلفة المشروعات.xlsx`);
    },
    async submit() {
      if (this.from && this.to) {
        const result = await axios.post(`/projects/TotalCostProjects`, {
          datefrom: this.from,
          dateto: this.to,
          project: this.project,
        });
        if (result.status == 200) {
          // (this.parent = ""), (this.parent2 = "");
          this.todate = this.to;
          this.fromdate = this.from;
          this.data1 = result.data.data;
          this.amount = this.data1.reduce(
            (acc, array) => acc + Number(array.amount),
            0
          );
          this.totalamount = this.data1.reduce(
            (acc, array) => acc + Number(array.totalamount),
            0
          );
          this.commission = this.data1.reduce(
            (acc, array) => acc + Number(array.commission),
            0
          );
        }
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: "incomestatement.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      });
    },
    allprojects() {
      this.project = this.projects
    }
  },
  watch: {
    async parent() {
      this.level2 = this.branches.filter(
        (x) => x.actnum.startsWith(this.parent) && x.actnum != this.parent
      );
      const result = await axios.get(
        `/reports/IncomeStatementcenter/${this.from}/${this.to}/${this.parent}`
      );
      if (result.status == 200) {
        this.todate = this.to;
        this.data1 = result.data.data;
        this.data2 = result.data.data2;
        this.data3 = result.data.data3;
        this.data4 = result.data.data4;
        this.data5 = result.data.data5;
        this.tincome = Number(
          this.data1[0].amount - this.data2[0].amount
        ).toFixed(2);
        this.tincomenot = Number(
          Number(this.tincome) +
          Number(this.data3[0].amount) -
          this.data4[0].amount
        ).toFixed(2);
        this.tincomet = Number(this.tincomenot - this.data5[0].amount).toFixed(
          2
        );
      }
    },
    async parent2() {
      const result = await axios.get(
        `/reports/IncomeStatementcenter/${this.from}/${this.to}/${this.parent2}`
      );
      if (result.status == 200) {
        this.todate = this.to;
        this.data1 = result.data.data;
        this.data2 = result.data.data2;
        this.data3 = result.data.data3;
        this.data4 = result.data.data4;
        this.data5 = result.data.data5;
        this.tincome = Number(
          this.data1[0].amount - this.data2[0].amount
        ).toFixed(2);
        this.tincomenot = Number(
          Number(this.tincome) +
          Number(this.data3[0].amount) -
          this.data4[0].amount
        ).toFixed(2);
        this.tincomet = Number(this.tincomenot - this.data5[0].amount).toFixed(
          2
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
  font-size: x-small;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
