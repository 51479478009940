<template>
    <div class="page">
        <div class="head-title">
            <div class="left">
                <ul class="breadcrumb">
                    <li>
                        <a href="#">{{ $t("management") }} {{ $t("projects") }}</a>
                    </li>
                    <li>-</li>
                    <li>
                        <a href="#">{{ $t("extracts") }}</a>
                    </li>
                    <li>-</li>
                    <li>
                        <a class="active" href="#">{{ $t("extract") }} - {{ alldata.cid }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="main">
            <div class="head">
                <div class="head1">
                    <h3>{{ $t("extract") }} EXT-{{ alldata.cid }}</h3>
                </div>
                <div class="head2">
                    <button class="buttonconfirm" :disabled="disabled" @click.prevent="edite"
                        v-if="alldata.status == 'waiting'">
                        {{ $t("Edite") }}
                    </button>
                    <button class="buttonconfirm" :disabled="disabled" @click="submit"
                        v-if="alldata.status == 'waiting' && hasPermission('confirm_extract_project')">
                        <i class="bi bi-file-check-fill"></i> {{ $t("confirm") }}
                    </button>
                    <button class="buttonconfirm" type="submit" @click="pdf">
                        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
                    </button>
                </div>

            </div>
            <div class="body">
                <div class="part1">
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">نوع المستخلص
                        </label>
                        <div class="col-sm-2">
                            <input type="text" disabled class="form-control form-control-sm" v-model="alldata.type">
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("contractor") }}
                        </label>
                        <div class="col-sm-4">
                            <input type="text" disabled class="form-control form-control-sm"
                                v-model="alldata.contractorname">
                        </div>
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("contracts") }}
                        </label>
                        <div class="col-sm-4">
                            <input type="text" disabled class="form-control form-control-sm" v-model="alldata.contract">
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("project") }}
                        </label>
                        <div class="col-sm-4">
                            <input type="text" disabled class="form-control form-control-sm" v-model="alldata.project">
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }}
                        </label>
                        <div class="col-sm-4">
                            <input type="date" class="form-control form-control-sm" v-model="alldata.date">
                        </div>
                    </div>
                    <div class="row mb-2">
                        استمارة للاعمال المنجزة للفترة
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("from") }}
                        </label>

                        <div class="col-sm-2">
                            <input type="date" class="form-control form-control-sm" id="inputEmail3"
                                v-model="alldata.startdate" />
                        </div>
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("to") }}
                        </label>

                        <div class="col-sm-2">
                            <input type="date" class="form-control form-control-sm" id="inputEmail3"
                                v-model="alldata.enddate" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("amount") }} {{ $t("extract")
                            }}
                        </label>
                        <div class="col-sm-4">
                            {{ alldata.amountwout }}
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("amount") }} {{
                            $t("guarantee")
                        }} %
                        </label>
                        <div class="col-sm-1">
                            {{ alldata.guarantee }} %
                        </div>
                        <div class="col-sm-1">
                            مبلغ : {{ alldata.guaranteeamount }} ر.س
                        </div>
                    </div>
                    <hr />
                    <h6>المستخلصات السابقة</h6>
                    <table class="table" style="font-size: small">
                        <thead>
                            <tr>
                                <th>رقم البند</th>
                                <th>{{ $t("product") }}</th>
                                <th>وحدة القياس</th>
                                <th>السعر</th>
                                <th>الكمية السابقة</th>
                                <th>الكمية الحالية</th>
                                <th style="background-color:black; color: white;">الكمية الاجمالية</th>
                                <th>المبلغ السابقة</th>
                                <th>المبلغ الحالية</th>
                                <th style="background-color:black; color: white;">المبلغ الاجمالى</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="x, id in totaltrans" :key="x">
                                <td v-if="x.unittype != 0">{{ id + 1 }}</td>
                                <td v-if="x.unittype != 0">{{ x.name }}</td>
                                <td v-if="x.unittype != 0">{{ x.unittype }}</td>
                                <td v-if="x.unittype != 0">{{ x.buyp }}</td>
                                <td v-if="x.unittype != 0">{{ x.paidq }}</td>
                                <td v-if="x.unittype != 0">{{ x.newq }}</td>
                                <td v-if="x.unittype != 0" style="background-color:black; color: white;">{{
                                    Number(x.paidq) +
                                    Number(x.newq) }}</td>
                                <td v-if="x.unittype != 0">{{ x.paid }}</td>
                                <td v-if="x.unittype != 0">{{ x.newamount }}</td>
                                <td v-if="x.unittype != 0" style="background-color:black; color: white;">{{
                                    Number(x.paid) +
                                    Number(x.newamount) }}</td>


                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="7">الاجمالى</td>
                            <td>{{ one }}</td>
                            <td>{{ two }}</td>
                            <td>{{ totalamountpaid }}</td>
                        </tfoot>
                    </table>
                    <hr />
                    <table class="table" style="width: 100%;" v-if="alldata.type == 'دفعة'">
                        <thead>
                            <tr>
                                <th>رقم البند</th>
                                <th style="width: 12%">{{ $t("product") }}</th>
                                <th style="width: 12%">{{ $t("classification") }}</th>
                                <th>{{ $t("description") }}</th>
                                <th style="width: 10%">{{ $t("quantity") }}</th>
                                <th style="width: 10%">{{ $t("unit type") }}</th>
                                <th>{{ $t("price") }}</th>
                                <!-- <th>{{ $t("befor") }} {{ $t("tax") }}</th> -->
                                <th>{{ $t("tax") }}</th>
                                <!-- <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                                <th>{{ $t("total") }}</th> -->
                                <th style="width: 10%">{{ $t("quantity") }}</th>
                                <th>مبلغ السداد</th>
                                <th> الضمان</th>
                                <th>الصافي</th>
                                <th>الضريبة</th>
                                <th>{{ $t("total") }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="x, id in contdata" :key="x">
                                <td>{{ id + 1 }}</td>
                                <td>{{ x.pronamear }}</td>
                                <td>{{ x.classname }}</td>
                                <td>
                                    {{ x.description }}
                                </td>
                                <td>
                                    {{ x.quantity }}
                                </td>
                                <td>{{ x.unittype }}</td>
                                <td>{{ x.buyp }}</td>
                                <!-- <td>{{ x.tbt }}</td> -->
                                <td>{{ x.tax }} %</td>
                                <!-- <td>{{ x.taxamount }}</td>
                                <td>{{ x.total }}</td> -->
                                <td style="width: 5%;"><input type="number" v-model="x.paidq" style="width: 100%;"
                                        @change="caltax(id)" />
                                </td>
                                <td style="width: 5%;">{{ x.paid }}
                                </td>
                                <td style="width: 5%;">
                                    <input type="number" v-model="x.guarantee" @change="calgar(id)" />
                                </td>
                                <td style="width: 5%;">{{ x.paidgar }}
                                </td>
                                <td style="width: 5%;">{{ x.taxamountpaid }}
                                </td>
                                <td>{{ x.total }}</td>
                                <td><a href="#" @click.prevent="del(id)"><i class="bi bi-x-circle-fill"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table" style="width: 100%;" v-if="alldata.type == 'ضمان'">
                        <thead>
                            <tr>
                                <th>رقم البند</th>
                                <th style="width: 12%">{{ $t("product") }}</th>
                                <th style="width: 12%">{{ $t("classification") }}</th>
                                <th>{{ $t("description") }}</th>
                                <th style="width: 10%">{{ $t("quantity") }}</th>
                                <th style="width: 10%">{{ $t("unit type") }}</th>
                                <th>{{ $t("price") }}</th>
                                <!-- <th>{{ $t("befor") }} {{ $t("tax") }}</th> -->
                                <th>{{ $t("tax") }}</th>
                                <th>سداد الضمان</th>
                                <th>الضريبة</th>
                                <th>الاجمالى</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="x, id in contdata" :key="x">
                                <td>{{ id + 1 }}</td>
                                <td>{{ x.pronamear }}</td>
                                <td>{{ x.classname }}</td>
                                <td>
                                    {{ x.description }}
                                </td>
                                <td>
                                    {{ x.quantity }}
                                </td>
                                <td>{{ x.unittype }}</td>
                                <td>{{ x.buyp }}</td>
                                <!-- <td>{{ x.tbt }}</td> -->
                                <td>{{ x.tax }} %</td>
                                <td style="width: 5%;"><input type="number" v-model="x.paidguarantee"
                                        style="width: 100%;" @change="caltax2(id)" />
                                </td>
                                <td>{{ x.taxpaidgar }}</td>
                                <td>{{ x.tpaidgar }}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
            <div id="main" style="padding: 1%;display: none;">
                <div style="padding-right: 45%"> <img src="../../assets/arinlogo.png"
                        style="height: 100px; width: 25%;" />
                </div>
                <div class="body" style="direction: rtl;">
                    <h3>{{ $t("extract") }} - {{ alldata.cid }}</h3>
                    <br />
                    <div class="part1">
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("contractor") }}
                            </label>
                            <div class="col-sm-4">
                                <span class="info">{{ alldata.contractorname }}</span>
                            </div>
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("contracts") }}
                            </label>
                            <div class="col-sm-4">
                                <span class="info">{{ alldata.contract }}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("project") }}
                            </label>
                            <div class="col-sm-4">
                                <span class="info">{{ alldata.project }}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }}
                            </label>
                            <div class="col-sm-4">
                                <span class="info">{{ alldata.date }}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("amount") }} {{
                                $t("extract")
                                }}
                            </label>
                            <div class="col-sm-4">
                                <span class="info">{{ alldata.amount }}</span>

                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("amount") }} {{
                                $t("guarantee")
                            }} %
                            </label>
                            <div class="col-sm-1">
                                {{ alldata.guarantee }} %
                            </div>
                            <div class="col-sm-3">
                                مبلغ : {{ alldata.guaranteeamount }} ر.س
                            </div>
                        </div>
                        <div class="row mb-2">
                            استمارة للاعمال المنجزة للفترة
                            {{ $t("from") }} : {{ alldata.startdate }}
                            {{ $t("to") }} : {{ alldata.enddate }}
                        </div>
                        <hr />
                        <table class="table" style="font-size: small">
                            <thead>
                                <tr>
                                    <th>رقم البند</th>
                                    <th>{{ $t("product") }}</th>
                                    <th>وحدة القياس</th>
                                    <th>السعر</th>
                                    <th>الكمية السابقة</th>
                                    <th>الكمية الحالية</th>
                                    <th style="background-color:black; color: white;">الكمية الاجمالية</th>
                                    <th>المبلغ السابقة</th>
                                    <th>المبلغ الحالية</th>
                                    <th style="background-color:black; color: white;">المبلغ الاجمالى</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="x, id in totaltrans" :key="x">
                                    <td v-if="x.unittype != 0">{{ id + 1 }}</td>
                                    <td v-if="x.unittype != 0">{{ x.name }}</td>
                                    <td v-if="x.unittype != 0">{{ x.unittype }}</td>
                                    <td v-if="x.unittype != 0">{{ x.buyp }}</td>
                                    <td v-if="x.unittype != 0">{{ x.paidq }}</td>
                                    <td v-if="x.unittype != 0">{{ x.newq }}</td>
                                    <td v-if="x.unittype != 0" style="background-color:black; color: white;">{{
                                        Number(x.paidq) +
                                        Number(x.newq) }}</td>
                                    <td v-if="x.unittype != 0">{{ x.paid }}</td>
                                    <td v-if="x.unittype != 0">{{ x.newamount }}</td>
                                    <td v-if="x.unittype != 0" style="background-color:black; color: white;">{{
                                        Number(x.paid) +
                                        Number(x.newamount) }}</td>


                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="7">الاجمالى</td>
                                <td>{{ one }}</td>
                                <td>{{ two }}</td>
                                <td>{{ totalamountpaid }}</td>
                            </tfoot>
                        </table>
                        <hr />

                        <div style="display: flex;">
                            <div class="sign" style="width: 25%;text-align: center;">المقاول</div>
                            <div class="sign" style="width: 25%;text-align: center;">المهندس</div>
                            <div class="sign" style="width: 25%;text-align: center;">الحسابات</div>
                            <div class="sign" style="width: 25%;text-align: center;">المدير العام</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import html2pdf from "html2pdf.js";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {

    async mounted() {
        const result1 = await axios.get(`/projects/getextract/${this.$route.params.id}`);
        // const result2 = await axios.get("/projects/allcontractors");
        if (result1.status == 200) {
            this.alldata = result1.data.data[0]
            // this.allcontractors = result2.data.data
            this.contractor = { name: this.alldata.contractorname, cid: this.alldata.contractorid }
            this.contdata = JSON.parse(this.alldata.contdata)
            this.totaltrans = JSON.parse(this.alldata.old)
            if (this.totaltrans.length > 0) {
                for (let i = 0; i < this.totaltrans.length; i++) {
                    const element = this.totaltrans[i];
                    const data = this.contdata.filter((x) => x.pronamear == element.name);
                    if (data.length == 0) {
                        element.newq = 0
                        element.newamount = 0
                        element.unittype = 0
                        element.buyp = 0
                    }
                    if (data.length > 0) {
                        element.newq = data[0].paidq
                        element.newamount = data[0].total
                        element.unittype = data[0].unittype
                        element.buyp = data[0].buyp
                    }
                }
                this.totaltrans = this.totaltrans.filter((x) => x.unittype != 0);
                this.one = this.totaltrans.reduce(
                    (acc, array) => acc + Number(array.paid),
                    0
                );
                this.two = this.contdata.reduce(
                    (acc, array) => acc + Number(array.total),
                    0
                );
                this.totalamountpaid = Number(Number(this.one) + Number(this.two)).toFixed(2)
            } else {
                for (let i = 0; i < this.contdata.length; i++) {
                    const element = this.contdata[i];
                    this.totaltrans.push({
                        newq: element.paidq,
                        newamount: element.total,
                        unittype: element.unittype,
                        buyp: element.buyp,
                        name: element.pronamear,
                        paid: 0,
                        paidq: 0
                    })
                }
                this.one = this.totaltrans.reduce(
                    (acc, array) => acc + Number(array.paid),
                    0
                );
                this.two = this.contdata.reduce(
                    (acc, array) => acc + Number(array.paidgar),
                    0
                );
                this.totalamountpaid = Number(Number(this.one) + Number(this.two)).toFixed(2)
            }
        }
    },
    props: ["css"],
    data() {
        return {
            disabled: false,
            v$: useValidate(),
            alldata: "",
            name: "",
            allprojects: [],
            allcontractors: [],
            contdata: [],
            project: "",
            contdate: '',
            startdate: "",
            enddate: "",
            amount: "",
            contractor: "",
            contracts: [],
            contract: "",
            totalextract: "",
            totaltrans: "",
            totalamountpaid: "",
            one: "",
            two: ""
        };
    },
    methods: {
        async del(id) {
            this.contdata.splice(id, 1);
            this.alldata.amount = this.contdata.reduce(
                (acc, array) => acc + Number(array.paid),
                0
            );
        },
        // caltax(id) {
        //     if (this.contdata[id].tax > 0) {
        //         const amount = this.contdata[id].paidq * this.contdata[id].buyp
        //         this.contdata[id].paidwithouttax = amount
        //         this.contdata[id].taxamountpaid = Number(amount * this.contdata[id].tax / 100).toFixed(2)
        //         this.contdata[id].paid = Number(Number(this.contdata[id].paidwithouttax) + Number(this.contdata[id].taxamountpaid)).toFixed(2)
        //         this.contdata[id].guarantee = this.contdata[id].paid * this.alldata.guarantee / 100,
        //             this.contdata[id].paidgar = this.contdata[id].paid - this.contdata[id].guarantee
        //     } else {
        //         this.contdata[id].paidwithouttax = Number(this.contdata[id].paidq * this.contdata[id].buyp).toFixed(2)
        //         this.contdata[id].taxamountpaid = 0
        //         this.contdata[id].paid = Number(Number(this.contdata[id].paidwithouttax) + Number(this.contdata[id].taxamountpaid)).toFixed(2)
        //         this.contdata[id].guarantee = this.contdata[id].paid * this.alldata.guarantee / 100
        //         this.contdata[id].paidgar = this.contdata[id].paid - this.contdata[id].guarantee
        //     }
        //     this.alldata.amountwout = this.contdata.reduce(
        //         (acc, array) => acc + Number(array.paidgar),
        //         0
        //     );
        //     this.alldata.guaranteeamount = this.contdata.reduce(
        //         (acc, array) => acc + Number(array.guarantee),
        //         0
        //     );
        // },
        caltax(id) {
            if (this.contdata[id].tax > 0) {
                const amount = this.contdata[id].paidq * this.contdata[id].buyp
                this.contdata[id].paid = Number(amount).toFixed(2)
                this.contdata[id].guarantee = amount * this.alldata.guarantee / 100,
                    this.contdata[id].paidgar = amount - this.contdata[id].guarantee
                this.contdata[id].taxamountpaid = Number(this.contdata[id].paidgar * this.contdata[id].tax / 100).toFixed(2)
                this.contdata[id].total = Number(Number(this.contdata[id].paidgar) + Number(this.contdata[id].taxamountpaid)).toFixed(2)
            } else {
                const amount = this.contdata[id].paidq * this.contdata[id].buyp
                this.contdata[id].paid = Number(amount).toFixed(2)
                this.contdata[id].taxamountpaid = 0
                this.contdata[id].guarantee = amount * this.alldata.guarantee / 100,
                    this.contdata[id].paidgar = amount - this.contdata[id].guarantee
                this.contdata[id].total = Number(Number(this.contdata[id].paidgar) + Number(this.contdata[id].taxamountpaid)).toFixed(2)
            }
            this.alldata.amountwout = this.contdata.reduce(
                (acc, array) => acc + Number(array.total),
                0
            );
            this.alldata.guaranteeamount = this.contdata.reduce(
                (acc, array) => acc + Number(array.guarantee),
                0
            );
            this.guaranteeamount = this.totalextract * this.guarantee / 100
        },
        caltax2(id) {
            if (this.contdata[id].tax > 0) {
                this.contdata[id].taxpaidgar = Number(this.contdata[id].paidguarantee * this.contdata[id].tax / 100)
                this.contdata[id].tpaidgar = Number(this.contdata[id].paidguarantee) + Number(this.contdata[id].paidguarantee * this.contdata[id].tax / 100)
            } else {
                this.contdata[id].taxpaidgar = 0
                this.contdata[id].tpaidgar = Number(this.contdata[id].paidguarantee)
            }
            this.alldata.amount = this.contdata.reduce(
                (acc, array) => acc + Number(array.paidguarantee),
                0
            );
            this.alldata.amountwout = this.contdata.reduce(
                (acc, array) => acc + Number(array.paidguarantee),
                0
            );
            this.alldata.guaranteeamount = this.contdata.reduce(
                (acc, array) => acc + Number(array.paidguarantee),
                0
            );
        },
        calgar(id) {
            if (this.contdata[id].tax > 0) {
                const amount = this.contdata[id].paidq * this.contdata[id].buyp
                this.contdata[id].paid = Number(amount).toFixed(2)
                this.contdata[id].paidgar = amount - this.contdata[id].guarantee
                this.contdata[id].taxamountpaid = Number(this.contdata[id].paidgar * this.contdata[id].tax / 100).toFixed(2)
                this.contdata[id].total = Number(Number(this.contdata[id].paidgar) + Number(this.contdata[id].taxamountpaid)).toFixed(2)
            } else {
                const amount = this.contdata[id].paidq * this.contdata[id].buyp
                this.contdata[id].paid = Number(amount).toFixed(2)
                this.contdata[id].taxamountpaid = 0
                this.contdata[id].paidgar = amount - this.contdata[id].guarantee
                this.contdata[id].total = Number(Number(this.contdata[id].paidgar) + Number(this.contdata[id].taxamountpaid)).toFixed(2)
            }
            this.alldata.amountwout = this.contdata.reduce(
                (acc, array) => acc + Number(array.total),
                0
            );
            this.alldata.guaranteeamount = this.contdata.reduce(
                (acc, array) => acc + Number(array.guarantee),
                0
            );
            this.guaranteeamount = this.totalextract * this.guarantee / 100
        },
        async submit() {

            this.disabled = true;
            const result = await axios.post(`/projects/confirmcontractorextract`, {
                username: store.state.randp.username,
                id: this.$route.params.id
            });
            if (result.status == 200) {
                this.redirectTo({ val: "AllExtracts" });
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                });

                Toast.fire({
                    icon: "success",
                    title: "Data Entry successfully",
                });
                // this.redirectTo({ val: "Home" });
            } else if (result.status == 401) {
                alert("Account Already Created Before");
            }

        },
        edite() {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Edite it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const del = await axios.post(`/projects/editeextract`, {
                        username: store.state.randp.username,
                        id: this.$route.params.id,
                        data: JSON.stringify(this.contdata),
                        amount: this.alldata.amount,
                        startdate: this.alldata.startdate,
                        enddate: this.alldata.enddate,
                        date: this.alldata.date,
                        amountwout: this.alldata.amountwout,
                        guaranteeamount: this.alldata.guaranteeamount
                    });
                    if (del.status == 200) {
                        Swal.fire("Edite!", "Your file has been Edited.", "success");
                    }
                }
            });
        },
        pdf() {
            html2pdf(document.getElementById("main").innerHTML, {
                margin: 0.2,
                filename: `${this.$route.params.id} - مستخلص.pdf`,
                image: { type: "jpeg", quality: 100 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
            });
        },
        ...mapActions(["redirectTo"]),
    },
    watch: {
        async contractor() {
            const result1 = await axios.get(`/projects/getconstractorcontract/${this.contractor.cid}`);
            if (result1.status == 200) {
                this.contracts = result1.data.data
            }
        },
        contract() {
            this.contdata = JSON.parse(this.contract.contdata)
        }
    },
};
</script>
<style lang="scss" scoped>
.page {
    direction: v-bind("css.EN");
    padding: 0.5%;
}

.main {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
}

.body {
    padding: 1%;
    // min-height: 500px;
    // border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part {
    font-size: large;
    width: 99%;
    padding: 2%;
    margin: 0.5%;
    background-color: white;
    border-radius: 5px;
    color: black;
    display: flex;
    // border: 1px solid black;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part .group {
    width: 50%;
    //   border: black 1px solid;
}

.part img {
    width: 100%;
    height: 100px;
    //   border: black 1px solid;
}

ul {
    text-decoration: none;
    list-style-type: none;
    color: black;
}

.bi {
    margin: 1%;
}

.head {
    padding: 0.25%;
    display: flex;
    border-bottom-style: ridge;
}

.head1 {
    width: 60%;
}

.head1 input {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
}

.head2 {
    width: 40%;
    text-align: center;
}

.head2 button {
    margin: 0.5%;
    padding: 1%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
    font-size: small;
    width: 15%;
}

.head2 button a {
    color: white;
}

.info {
    width: 500px;
    background-color: #eee;
    padding: 1%;
}
</style>