<template>
    <div class="page">
        <div class="head-title">
            <div class="left">
                <ul class="breadcrumb">
                    <li>
                        <router-link to="/stock/HomeStock" class="active">{{
                            $t("manufacturing")
                            }}</router-link>
                    </li>
                    <li>-</li>
                    <li>
                        <router-link to="" class="active"> {{ $t("order") }} {{ $t("manufacturing")
                            }} - MF-{{ this.$route.params.id }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="main">
            <div class="head">
                <div class="head1">
                    <h3>{{ $t("order") }} {{ $t("manufacturing")
                        }} MF-{{ this.$route.params.id }}</h3>
                </div>
                <div class="head2">
                    <button class="buttonconfirm" type="submit" v-if="!disabled" :disabled="disabled" @click="submite"
                        style="background-color: green">
                        {{ $t("confirm") }} {{ $t("order") }} {{ $t("manufacturing") }}
                    </button>
                    <button class="buttonconfirm" type="submit" v-if="!disabled" @click="Edite">
                        {{ $t("Edite") }}
                    </button>
                </div>
            </div>
            <div class="body">
                <div class="part1"></div>
            </div>
            <div class="body2">
                <div>
                    <div class="row mb-3">
                        <label for="inputPassword3" class="col-sm-2 col-form-label">{{ $t("date") }}
                        </label>
                        <div class="col-sm-3">
                            <input type="date" class="form-control" :disabled="disabled" id="inputPassword3"
                                v-model="alldata.date" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="inputPassword3" class="col-sm-2 col-form-label"> {{ $t("date") }} {{ $t("start") }}
                        </label>
                        <div class="col-sm-3">
                            <input type="date" class="form-control" :disabled="disabled" id="inputPassword3"
                                v-model="alldata.startdate" />
                        </div>

                        <label for="inputPassword3" class="col-sm-2 col-form-label">{{ $t("date") }} {{ $t("end") }}
                        </label>
                        <div class="col-sm-3">
                            <input type="date" class="form-control" :disabled="disabled" id="inputPassword3"
                                v-model="alldata.enddate" />
                        </div>
                    </div>
                    <!--  -->
                    <div class="row mb-3">
                        <label for="inputPassword3" class="col-sm-2 col-form-label">{{ $t("name") }} {{ $t("order") }}

                        </label>
                        <div class="col-sm-3">
                            <input type="text" class="form-control" :disabled="disabled" id="inputPassword3"
                                v-model="alldata.name" />
                        </div>
                    </div>
                    <!--  -->
                    <div class="row mb-3">
                        <label for="inputPassword3" class="col-sm-2 col-form-label">{{ $t("ccenter") }}
                        </label>
                        <div class="col-sm-3">
                            <input type="text" disabled class="form-control" id="inputPassword3"
                                v-model="alldata.costname" />
                        </div>
                    </div>


                    <div class="row mb-3" v-if="!disabled">
                        <label for="inputPassword3" class="col-sm-2 col-form-label">{{
                            $t("product")
                            }}</label>
                        <div class="col-sm-3">
                            <v-select :options="products" label="namear" v-model="product">
                                <template #option="option">
                                    <div style="text-align: center">
                                        <span>{{ option.namear }} - {{ option.barcode }} -
                                            {{ option.unittype }}
                                        </span>
                                    </div>
                                </template>
                            </v-select>
                        </div>
                    </div>

                    <table class="table">
                        <thead>
                            <tr>
                                <td colspan="8">{{ $t("details") }} {{ $t("materials") }}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>{{ $t("materials") }}</td>
                                <td>{{ $t("code") }}</td>
                                <td>{{ $t("unit") }}</td>
                                <td>{{ $t("stock") }}</td>
                                <td>{{ $t("cost") }}</td>
                                <td>
                                    {{ $t("quantity") }}
                                </td>
                                <td v-if="!disabled"></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(x, id) in data2" :key="x">
                                <td>{{ id + 1 }}</td>
                                <td>
                                    {{ x.namear }}
                                </td>
                                <td>
                                    {{ x.sn }}
                                </td>
                                <td>
                                    {{ x.unittype }}
                                </td>
                                <td>
                                    {{ x.stock }}
                                </td>
                                <td>
                                    {{ x.cost }}
                                </td>
                                <td>
                                    <input type="floating" v-model="x.quantity" />
                                </td>
                                <td v-if="!disabled">
                                    <a href="#" @click.prevent="del(id)"><i class="bi bi-x-circle-fill"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="group">
                        <h5>{{ $t("notes") }}</h5>
                        <textarea v-model="alldata.description" id="" :disabled="disabled" style="width: 100%"
                            rows="3"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import moment from "moment";

export default {
    components: {
        vSelect,
    },
    props: ["css"],
    data() {
        return {
            disabled: false,
            date: moment().format("YYYY-MM-DD"),
            data2: [],
            products: [],
            product: {},
            parent: "",
            parent2: "",
            level1: [],
            level2: [],
            accounts: [],
            account: {},
            dep: store.state.dep,
            costcenter: "",
            startdate: "",
            enddate: "",
            name: "",
            notes: "",
            alldata: ""
        };
    },

    async mounted() {
        const result = await axios.get(`/data/getmanufacturingorder/${this.$route.params.id}`);
        const result2 = await axios.get(`/data/allproducts2`);
        const result1 = await axios.get("/data/getcostcenter");
        if (result2.status == 200) {
            this.alldata = result.data.data[0]
            this.products = result2.data.data;
            this.costcenter = result1.data.data;
            this.level1 = result1.data.data;
            this.data2 = JSON.parse(this.alldata.details)
        }
        if (this.alldata.status != 'waiting') {
            this.disabled = true
        }
    },
    methods: {
        Edite() {
            Swal.fire({
                title: this.$t("Edite") + " " + this.$t("order"),
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.$t("confirm"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const action = await axios.post("/data/editemanufacturingorder", {
                        id: this.$route.params.id,
                        date: this.alldata.date,
                        startdate: this.alldata.startdate,
                        enddate: this.alldata.enddate,
                        name: this.alldata.name,
                        data: JSON.stringify(this.data2),
                        username: store.state.randp.username,
                        notes: this.alldata.description
                    });
                    if (action.status == 200) {
                        Swal.fire(this.$t("Edite") + " " + this.$t("order"));
                        this.redirectTo({ val: "AllManufacturingOrders" });
                    }
                }
            });
        },
        submite() {
            Swal.fire({
                title: this.$t("confirm") + " " + this.$t("order"),
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.$t("confirm"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const action = await axios.post("/data/confirmmanufacturingorder", {
                        id: this.$route.params.id,
                        username: store.state.randp.username,
                    });
                    if (action.status == 200) {
                        Swal.fire(this.$t("confirm") + " " + this.$t("order"));
                        this.redirectTo({ val: "AllManufacturingOrders" });
                    }
                }
            });
        },
        async del(id) {
            this.data2.splice(id, 1);

        },
        ...mapActions(["redirectTo"]),
    },
    watch: {
        async product() {
            const filter = this.data2.filter((x) => x.sn == this.product.sn);
            if (filter.length == 0) {
                const stockvalidationbalance = await axios.get(
                    `/reports/stockvalidationbalance/${this.product.sn}`
                );
                if (stockvalidationbalance.status == 200) {
                    const data = stockvalidationbalance.data.data[0][0];
                    const stockbalance = data.dpt - data.crd;

                    this.data2.push({
                        namear: this.product.namear,
                        sn: this.product.sn,
                        unittype: this.product.unittype,
                        stock: stockbalance,
                        // cost: stockvalidationbalance.data.data[1],
                        cost: this.product.rawmaterialcost,
                        quantity: 0,
                    });
                    this.total = (this.data2.reduce((acc, array) => acc + Number(array.cost), 0)
                    ).toFixed(2)

                } else {
                    alert("تم اضافة المنتج من قبل");
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.page {
    // direction: v-bind("css.EN");
    padding: 0.5%;
}

.main {
    width: 98%;
    margin: 1%;
    padding: 1%;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    border: ridge 0.5px;
}

.head {
    padding: 0.25%;
    display: flex;
    border-bottom-style: ridge;
}

.head1 {
    width: 70%;
}

.head1 input {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
}

.head2 {
    width: 30%;
    text-align: center;
}

button {
    margin: 2%;
    padding: 2%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
}

.head2 button a {
    color: white;
}

.table {
    margin-top: 1%;
}

table thead tr {
    border-radius: 5px;
    background-color: #eee;
}

select {
    margin: 0.5%;
    // padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
}

.body {
    display: flex;
    margin: 1%;
}

.part1 {
    width: 50%;
}

.body2 {
    width: 100%;
    // padding: 1%;
    //   border: 1px solid black;
    border-radius: 5px;
    text-align: center;
}

table input {
    text-align: center;
    width: 50px;
    border: none;
}

@media screen and (max-width: 576px) {
    .table {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        background-color: white;
        font-size: x-small;
    }
}
</style>