<template>
    <div class="page">
        <div class="head-title">
            <div class="left">
                <ul class="breadcrumb">
                    <li>
                        <a href="#">{{ $t("management") }} {{ $t("projects") }}</a>
                    </li>
                    <li>-</li>
                    <li>
                        <a href="#">{{ $t("contractors") }}</a>
                    </li>
                    <li>-</li>
                    <li>
                        <a class="active" href="#">{{ $t("contract") }} {{ $t("contractor") }} -
                            {{ this.$route.params.id }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="main">
            <div class="head">
                <div class="head1">
                    <h3> {{ $t("contract") }} - {{ this.$route.params.id }}</h3>
                </div>
                <div class="head2">
                    <button class="btn btn-primary" :disabled="disabled" @click.prevent="confirm">
                        {{ $t("Edite") }}
                    </button>
                    <!-- Button trigger modal -->
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        @click="getextracts" style="background-color: red;" v-if="alldata.status == 'active'">
                        انهاء العقد
                    </button>

                    <!-- Modal -->
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">انهاء العقد</h5>
                                </div>
                                <div class="modal-body">
                                    {{ $t("extracts") }}
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>{{ $t("extract") }}</th>
                                                <th>{{ $t("amount") }}</th>
                                                <th>{{ $t("date") }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="x in allextracts" :key="x">
                                                <td>{{ x.cid }}</td>
                                                <td>{{ x.amount }}</td>
                                                <td>{{ x.date }}</td>
                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>{{ $t("total") }}</td>
                                                <td>{{ totalamountextracts }}</td>
                                            </tr>
                                        </tfoot>
                                    </table>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary" @click="endcontract"
                                        style="background-color: red;">انهاء
                                        العقد</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="body">
                <div class="part1">
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("project") }} <span
                                class="error-feedback">{{
                                    "*"
                                }}</span>
                        </label>
                        <div class="col-sm-4">

                            <input type="text" disabled class="form-control form-control-sm" id="inputEmail3"
                                v-model="alldata.project" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("name") }} {{ $t("contract")
                            }}
                            <span class="error-feedback">{{
                                "*"
                            }}</span></label>
                        <div class="col-sm-4">
                            <input type="text" class="form-control form-control-sm" id="inputEmail3"
                                v-model="alldata.name" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} {{ $t("contract")
                            }}
                            <span class="error-feedback">{{
                                "*"
                            }}</span></label>
                        <div class="col-sm-2">
                            <input type="date" class="form-control form-control-sm" id="inputEmail3"
                                v-model="alldata.contdate" />
                        </div>
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} {{ $t("start") }}
                            <span class="error-feedback">{{
                                "*"
                            }}</span></label>

                        <div class="col-sm-2">
                            <input type="date" lass="form-control form-control-sm" id="inputEmail3"
                                v-model="alldata.startdate" />
                        </div>
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} {{ $t("end") }}
                            <span class="error-feedback">{{
                                "*"
                            }}</span></label>

                        <div class="col-sm-2">
                            <input type="date" class="form-control form-control-sm" id="inputEmail3"
                                v-model="alldata.enddate" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("amount") }} {{ $t("contract")
                            }}
                            <span class="error-feedback">{{
                                "*"
                            }}</span></label>
                        <div class="col-sm-4">
                            <input type="number" disabled class="form-control form-control-sm" id="inputEmail3"
                                v-model="alldata.amount" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("amount") }} {{
                            $t("guarantee")
                            }}%
                            <span class="error-feedback">{{
                                "*"
                            }}</span></label>
                        <div class="col-sm-1">
                            <input type="number" class="form-control form-control-sm" id="inputEmail3"
                                v-model="alldata.guarantee" />
                        </div>
                    </div>
                    <h5>بيانات الطرف الثاني</h5>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("contractor") }} <span
                                class="error-feedback">{{
                                    "*"
                                }}</span>
                        </label>
                        <div class="col-sm-4">

                            <input type="text" disabled class="form-control form-control-sm" id="inputEmail3"
                                v-model="alldata.contractorname" />
                        </div>
                    </div>
                    <h5>اتفقا على مايلى :</h5>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("product") }}
                        </label>
                        <div class="col-sm-2">
                            <v-select :options="products" label="namear" v-model="product">
                                <template #option="option">
                                    <span>{{ option.barcode }} - {{ option.namear }}</span>
                                </template>
                            </v-select>
                        </div>
                        <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("classification") }}
                        </label>
                        <div class="col-sm-2">
                            <v-select :options="alltasks" label="name" v-model="thetask">
                                <template #option="option">
                                    <span>{{ option.name }}</span>
                                </template>
                            </v-select>
                        </div>
                        <label class="col-sm-1 col-form-label-sm">{{ $t("tax") }}
                        </label>
                        <div class="col-sm-1">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                v-model="cofirmtax" />
                        </div>
                        <div class="col-sm-2">
                            <button class="btn btn-primary" @click="Addproduct" style="padding: 1%">
                                {{ $t("add") }}
                            </button>
                        </div>
                    </div>
                    <hr />
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th style="width: 3%">{{ $t("product") }}</th>
                                <th style="width: 3%">{{ $t("classification") }}</th>
                                <th>{{ $t("description") }}</th>
                                <th style="width: 10%">{{ $t("quantity") }}</th>
                                <th style="width: 10%">{{ $t("unit type") }}</th>
                                <th>{{ $t("price") }}</th>
                                <th>{{ $t("total") }} {{ $t("befor") }} {{ $t("tax") }}</th>
                                <th>{{ $t("tax") }}</th>
                                <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                                <th>{{ $t("total") }}</th>
                                <th>{{ $t("cancel") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, id) in data" :key="value">
                                <td>{{ id + 1 }}</td>
                                <td style="width: 12%">
                                    <span>{{ value.barcode }} - {{ value.pronamear }}</span>
                                </td>
                                <td style="width: 12%">
                                    <span>{{ value.classname }}</span>
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text"
                                        v-model="value.description" />
                                </td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" v-model="value.quantity"
                                        @change="
                                            value.tbt = Number(
                                                value.buyp * value.quantity -
                                                (value.buyp * value.quantity * value.discount) / 100
                                            ).toFixed(2);
                                        value.taxamount = Number(
                                            (value.tbt * value.tax) / 100
                                        ).toFixed(2);
                                        value.total = Number(
                                            Number(value.tbt) + (value.tbt * value.tax) / 100
                                        ).toFixed(2);
                                        this.calc();
                                        " />
                                </td>
                                <td>{{ value.unittype }}</td>
                                <td>
                                    <input class="form-control form-control-sm" type="text" v-model="value.buyp"
                                        @change="
                                            value.tbt = Number(
                                                value.buyp * value.quantity -
                                                (value.buyp * value.quantity * value.discount) / 100
                                            ).toFixed(2);
                                        value.taxamount = Number(
                                            (value.tbt * value.tax) / 100
                                        ).toFixed(2);
                                        value.total = Number(
                                            Number(value.tbt) + (value.tbt * value.tax) / 100
                                        ).toFixed(2);
                                        this.calc();
                                        " />
                                </td>
                                <td>{{ value.tbt }}</td>
                                <td style="display: flex">
                                    <input type="number" v-model="value.tax" style="width: 100px" @change="
                                        value.tbt =
                                        value.buyp * value.quantity -
                                        (value.buyp * value.quantity * value.discount) / 100;
                                    value.taxamount = (value.tbt * value.tax) / 100;
                                    value.total = value.tbt + (value.tbt * value.tax) / 100;
                                    this.calc();
                                    " />
                                    %
                                </td>
                                <td>{{ value.taxamount }}</td>
                                <td>{{ value.total }}</td>
                                <td>
                                    <a href="#" @click.prevent="del(id)"><i class="bi bi-x-circle-fill"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div style="clear: both; margin: 5%; text-align: center">

                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import store from "@/store";
import useValidate from "@vuelidate/core";

import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
export default {
    components: {
        vSelect,
    },
    async mounted() {
        const result = await axios.get(`/projects/getcontractorcontract/${this.$route.params.id}`);
        const result1 = await axios.get("/projects/allcontractors");
        const result4 = await axios.get("/data/allprojects");
        const result5 = await axios.get("/data/alltasks");
        const result2 = await axios.get("/data/allproducts");
        if (result4.status == 200) {
            this.alldata = result.data.data
            this.allprojects = result4.data.data;
            this.allcontractors = result1.data.data;
            this.alltasks = result5.data.data;
            this.products = result2.data.data;
            this.data = JSON.parse(this.alldata.contdata)
        }
    },
    props: ["css"],
    data() {
        return {
            disabled: false,
            v$: useValidate(),
            name: "",
            allprojects: [],
            allcontractors: [],
            contdata: [{}],
            project: "",
            contdate: '',
            startdate: "",
            enddate: "",
            amount: "",
            contractor: "",
            alltasks: [],
            products: [],
            product: "",
            thetask: "",
            data: [],
            alldata: "",
            allextracts: "",
            totalamountextracts: ""
        };
    },
    methods: {
        async confirm() {
            this.disabled = true;
            const result = await axios.post(`/projects/editecontractorcontract`, {
                amount: this.alldata.amount,
                contdata: JSON.stringify(this.data),
                id: this.$route.params.id,
                guarantee: this.alldata.guarantee,
                name: this.alldata.name,
                contdate: this.alldata.contdate,
                startdate: this.alldata.startdate,
                enddate: this.alldata.enddate,
            });
            if (result.status == 200) {
                this.redirectTo({ val: "allcontractorscontracts" });
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                });

                Toast.fire({
                    icon: "success",
                    title: "Data Entry successfully",
                });
                // this.redirectTo({ val: "Home" });
            } else if (result.status == 401) {
                alert("Account Already Created Before");
            }

        },
        async del(id) {
            this.data.splice(id, 1);
            this.calc();
        },
        Addproduct() {
            if (this.product && this.thetask) {
                const data2 = this.data.filter((x) => x.pronamear == this.product.namear);
                if (data2.length == 0) {
                    if (this.cofirmtax) {
                        this.data.push({
                            proid: this.product.pid,
                            pronamear: this.product.namear,
                            pronameen: this.product.nameen,
                            salep: this.product.salep,
                            salec: this.product.salec,
                            snamear: this.product.snamear,
                            snameen: this.product.snameen,
                            buyp: this.product.buyp,
                            buyc: this.product.buyc,
                            bnamear: this.product.bnamear,
                            bnameen: this.product.bnameen,
                            discount: this.product.discount,
                            unittype: this.product.unittype,
                            tax: this.product.tax,
                            sn: this.product.sn,
                            stock: this.product.stock,
                            barcode: this.product.barcode,
                            quantity: 1,
                            tbt:
                                this.product.buyp * 1 -
                                (this.product.buyp * 1 * this.product.discount) / 100,
                            taxamount: Number(
                                ((this.product.buyp * 1 -
                                    (this.product.buyp * 1 * this.product.discount) / 100) *
                                    this.product.tax) /
                                100
                            ).toFixed(2),
                            total: Number(
                                this.product.buyp * 1 -
                                (this.product.buyp * 1 * this.product.discount) / 100 +
                                ((this.product.buyp * 1 -
                                    (this.product.buyp * 1 * this.product.discount) / 100) *
                                    this.product.tax) /
                                100
                            ).toFixed(2),
                            classname: this.thetask.name,
                            classid: this.thetask.tid,
                            item: this.product.item,
                            project: this.project.name,
                            projectid: this.project.pid,
                        });
                    } else {
                        this.data.push({
                            proid: this.product.pid,
                            pronamear: this.product.namear,
                            pronameen: this.product.nameen,
                            salep: this.product.salep,
                            salec: this.product.salec,
                            snamear: this.product.snamear,
                            snameen: this.product.snameen,
                            buyp: this.product.buyp,
                            buyc: this.product.buyc,
                            bnamear: this.product.bnamear,
                            bnameen: this.product.bnameen,
                            discount: this.product.discount,
                            unittype: this.product.unittype,
                            tax: 0,
                            sn: this.product.sn,
                            stock: this.product.stock,
                            barcode: this.product.barcode,
                            quantity: 1,
                            tbt:
                                this.product.buyp * 1 -
                                (this.product.buyp * 1 * 0) / 100,
                            taxamount: 0,
                            total: Number(
                                this.product.buyp * 1 -
                                (this.product.buyp * 1 * this.product.discount) / 100 +
                                ((this.product.buyp * 1 -
                                    (this.product.buyp * 1 * this.product.discount) / 100) *
                                    0) /
                                100
                            ).toFixed(2),
                            classname: this.thetask.name,
                            classid: this.thetask.tid,
                            item: this.product.item,
                            project: this.project.name,
                            projectid: this.project.pid,
                        });
                    }
                    this.calc();
                }
            }
        },
        calc() {
            this.alldata.amount = this.data.reduce(
                (acc, array) => acc + Number(array.total),
                0
            );
        },
        async getextracts() {
            const extracts = await axios.get(`/projects/getconstractorcontracttran/${this.$route.params.id}`)
            if (extracts.status == 200) {
                this.allextracts = extracts.data.data
                this.totalamountextracts = this.allextracts.reduce(
                    (acc, array) => acc + Number(array.amount),
                    0
                );
            }
        },
        async endcontract() {
            Swal.fire({
                title: this.$t("confirm"),
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.$t("confirm"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const action = await axios.post("/projects/deletecontractorscontracts", {
                        id: this.$route.params.id
                    });
                    if (action.status == 200) {
                        Swal.fire(this.$t("done"));
                        // this.redirectTo({ val: "allcontractorscontracts" });
                        window.location.href = `${this.$store.state.surl}/ProjectsManagement/contractors/allcontractorscontracts`;
                    }
                }
            });
        },
        ...mapActions(["redirectTo"]),
    },
    watch: {
    },
};
</script>
<style lang="scss" scoped>
.page {
    direction: v-bind("css.EN");
    padding: 0.5%;
}

.main {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
}

.body {
    padding: 1%;
    // min-height: 500px;
    // border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part {
    font-size: large;
    width: 99%;
    padding: 2%;
    margin: 0.5%;
    background-color: white;
    border-radius: 5px;
    color: black;
    display: flex;
    // border: 1px solid black;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part .group {
    width: 50%;
    //   border: black 1px solid;
}

.part img {
    width: 100%;
    height: 100px;
    //   border: black 1px solid;
}

ul {
    text-decoration: none;
    list-style-type: none;
    color: black;
}

.bi {
    margin: 1%;
}

.head {
    padding: 0.25%;
    display: flex;
    border-bottom-style: ridge;
}

.head1 {
    width: 60%;
}

.head1 input {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
}

.head2 {
    width: 40%;
    text-align: center;
}

button {
    margin: 0.5%;
    padding: 2%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
}

.head2 button {
    margin: 0.5%;
    padding: 1%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
    font-size: small;
    width: 30%;
}

.head2 button a {
    color: white;
}
</style>