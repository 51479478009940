<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("management") }} {{ $t("projects") }}</a>
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active">{{ $t("receipts") }} {{ $t("paid") }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active">{{ $t("add") }} {{ $t("receipts") }}
              {{ $t("paid") }} {{ $t("contractor") }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>
            <i class="bi bi-credit-card"></i> {{ $t("receipts") }}
            {{ $t("paid") }} {{ $t("contractor") }}
          </h3>
        </div>
        <div class="head2">
          <button class="buttonconfirm" type="submit" :disabled="disabled" @click="submit">
            {{ $t("save") }}
          </button>
        </div>
      </div>
      <div class="body">
        <div class="body1">
          <div class="group">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("contractor") }} <span
                  class="error-feedback">{{
                    "*"
                  }}</span>
              </label>
              <div class="col-sm-4">
                <v-select :options="allcontractors" label="name" v-model="contractor">
                  <template #option="option">
                    <span>{{ option.name }}</span>
                  </template>
                </v-select>
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("contracts") }} <span
                  class="error-feedback">{{
                    "*"
                  }}</span>
              </label>
              <div class="col-sm-4">
                <v-select :options="contracts" label="name" v-model="contract">
                  <template #option="option">
                    <span>{{ option.name }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("amount") }} {{ $t("contract")
                }}
              </label>
              <div class="col-sm-4">
                <input type="text" disabled class="form-control form-control-sm" v-model="contract.amount">
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("total") }} {{ $t("amount") }} {{
                $t("extracts")
              }}
              </label>
              <div class="col-sm-4">
                <input type="text" disabled class="form-control form-control-sm" v-model="totalextractamount">
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">اجمالى الدفعات المسددة
              </label>
              <div class="col-sm-4">
                <input type="text" disabled class="form-control form-control-sm" v-model="totalreceiptsamount">
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">رقم المستخلص
              </label>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" v-model="extract">
              </div>
            </div>

          </div>

        </div>
        <div class="body1" v-if="allextracts.length > 0">
          <h5>{{ $t("extracts") }}</h5>
          <br />
          <table class="table">
            <thead>
              <tr>
                <th>*</th>
                <th>{{ $t("date") }}</th>
                <th>{{ $t("amount") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(x, id) in allextracts" :key=x>
                <td>{{ id + 1 }}</td>
                <td>{{ x.startdate }}</td>
                <td>{{ x.amount }}</td>
              </tr>
            </tbody>
          </table>

        </div>
        <br />
        <div class="body1">
          <div class="group" v-if="ptype == 'inhouse'">
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label">{{ $t("Method of Payment") }}
              </label>
              <div class="col-sm-7">
                <v-select :options="methods" label="category" v-model="Method">
                  <template #option="option">
                    <div class="option">
                      <span>{{ option.category }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label">{{ $t("Account") }} {{ $t("payment") }}
              </label>
              <div class="col-sm-7">
                <v-select :options="accountpayment" label="namear" v-model="accountpay">
                  <template #option="option">
                    <div class="option">
                      <span>{{ option.namear }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="group" v-if="ptype == 'other'">
            <div class="row mb-1">
              <label class="col-sm-5 col-form-label"> نوع السداد </label>
              <div class="col-sm-4">

                <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="ownerpayment">
                  <option value="true">سداد عهدة مشروع </option>
                  <option value="false">سداد المالك</option>
                </select>
              </div>
            </div>


          </div>
          <div class="group">
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label">{{ $t("date") }} {{ $t("payment") }}
                <span class="error-feedback" v-if="v$.paymentdate.$error">{{
                  "*"
                }}</span></label>
              <div class="col-sm-6">
                <input type="date" class="form-control" v-model="paymentdate" />
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label">{{ $t("amount") }}
                <span class="error-feedback" v-if="v$.amount.$error">{{
                  "*"
                }}</span></label>
              <div class="col-sm-6">
                <input type="text" class="form-control" v-model="amount" />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="body1">
          <div class="group">
            <div class="row mb-2">
              <label class="col-sm-2 col-form-label-sm">
                <i class="bi bi-pin-angle-fill"></i> {{ $t("add") }}
                {{ $t("attachments") }}
              </label>
              <div class="col-sm-8">
                <div>
                  <div>
                    <input type="file" id="files" ref="files" multiple v-on:change="handleFilesUpload()" />
                  </div>
                  <br />
                  <!-- <div v-if="files.length > 0">
                    <div v-for="(file, key) in files" :key="file">
                      {{ file.name }} &nbsp;
                      <a href="#" class="remove-file" v-on:click="removeFile(key)"
                        >Remove</a
                      >
                      </div>
                    </div> -->
                  <table class="table" style="width: 100%">
                    <thead>
                      <tr>
                        <th>{{ $t("name") }}</th>
                        <th>{{ $t("cancel") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(file, key) in files" :key="file">
                        <td>{{ file.name }}</td>
                        <td>
                          <a href="#" class="remove-file" v-on:click="removeFile(key)"><i
                              class="bi bi-x-circle-fill"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="group">
          <h5>{{ $t("notes") }}</h5>
          <textarea v-model="notes" id="" style="width: 100%" rows="3"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import axios from "axios";
import store from "@/store";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
export default {
  props: ["css"],
  components: {
    vSelect,
  },
  async mounted() {
    const result1 = await axios.get("/projects/allcontractors");
    if (result1.status == 200) {
      this.allcontractors = result1.data.data;
    }
    const result2 = await axios.get("/data/accountpayment");
    if (result2.status == 200) {
      this.accountpayment = result2.data.data;
    }
  },
  data() {
    return {
      v$: useValidate(),
      allcontractors: [],
      contractor: {},
      comm: "",
      contracts: [],
      contract: {},
      contstatus: "",
      contstart: "",
      conttype: "",
      contduration: "",
      contamount: "",
      methods: [
        { category: "نقدى", value: "cash" },
        { category: "حوالة بنكية", value: "bank transfer" },
        { category: "لحساب المالك", value: "owner account" },
        { category: "شيك", value: "check" },
        { category: "خصم من التامين", value: "insurance discount" },
        {
          category: "خصم من دفعة مقدمة",
          value: "discount from advance payment",
        },
        { category: "عهدة نقدية", value: "monetary contract" },
      ],
      Method: {},
      accountpayment: [],
      account: "",
      paymentdate: "",
      conductor: "",
      commission: [],
      paymentamount: "",
      propertyaccount: "",
      propertytaxaccount: "",
      renteraccount: "",
      totalcommission: 0,
      totalcommissiontax: 0,
      unit: "",
      notes: "",
      commissionaccount: "",
      ownerid: "",
      ownername: "",
      ownerbalance: "",
      accountpay: "",
      amount: "",
      files: [],
      fileURL: [],
      allextracts: [],
      totalextractamount: 0,
      ptype: '',
      ownerpayment: '',
      totalreceiptsamount: 0,
      extract: ""
    };
  },
  validations() {
    return {
      contract: { required },
      paymentdate: { required },
      amount: { required },
    };
  },
  methods: {
    async submit() {
      this.fileURL = []
      let uploadedFiles = this.$refs.files.files;

      for (var x = 0; x < uploadedFiles.length; x++) {
        const type = uploadedFiles[x].name.split(".");
        this.fileURL.push({ name: uploadedFiles[x].name, type: type[1] });
      }
      this.v$.$validate();
      if (!this.v$.$error) {
        Swal.fire({
          title: this.$t("confirm") + "-" + this.$t("payment"),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("confirm"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            const action = await axios.post("/projects/createwaitprojectreceipt", {
              username: store.state.randp.username,
              proid: this.contract.projectid,
              proname: this.contract.project,
              ownerid: this.contractor.cid,
              ownername: this.contractor.name,
              account: JSON.stringify(this.account),
              amount: this.amount,
              paytype: this.Method.category,
              payaccount: JSON.stringify(this.accountpay),
              paydate: this.paymentdate,
              description: this.notes,
              fileurl: JSON.stringify(this.fileURL),
              ownerpayment: this.ownerpayment,
              teamount: this.totalextractamount,
              tramount: this.totalreceiptsamount,
              contractid: this.contract.cid,
              ptype: this.ptype,
              extract: this.extract
            });
            if (action.status == 200) {
              const id = action.data.data
              for (var i = 0; i < this.files.length; i++) {
                let file = this.files[i];
                let formData = new FormData();
                let name = this.files[i].name.split(".");
                let newname = store.state.cnum[0].cnum + "_" + id + "_" + i + "." + name[name.length - 1];
                // this.fileURL.push({ name: newname });
                formData.append("file", file, newname);
                await axios.patch(`/receiptowner/${i}`, formData);
              }
              Swal.fire(this.$t("done") + " " + this.$t("payment"));
              this.redirectTo({ val: "AllExtractsPayment" });
            }
          }
        });
      }
    },
    addFiles() {
      this.$refs.files.click();
    },
    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    async contractor() {
      const result1 = await axios.get(`/projects/getconstractorcontract/${this.contractor.cid}`);
      if (result1.status == 200) {
        this.contracts = result1.data.data
        this.contract = {}
      }
    },
    async contract() {
      const result2 = await axios.get(`/projects/gettotalextract/${this.contract.cid}/${this.contract.projectid}`);
      if (result2.status == 200) {
        this.allextracts = result2.data.data[0]
        this.totalextractamount = this.allextracts.reduce(
          (acc, array) => acc + Number(array.amount),
          0
        );
        this.ptype = result2.data.data[1].ptype
        this.totalreceiptsamount = result2.data.data[2].amount

      }
    }
  },

};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  padding: 1%;
  // min-height: 500px;
  //   border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.body1 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  border: 1px solid black;
  display: flex;
  border-radius: 5px;
}

.body2 {
  width: 100%;
  min-height: 100px;
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
}

.body3 {
  width: 100%;
  min-height: 100px;
}

.body4 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  background-color: #eee;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  border: 1px solid black;
  font-size: small;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 60%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 40%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 10%;
}

.head2 button a {
  color: white;
}

.group {
  width: 100%;
}

.group2 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group3 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group input {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.group2 input {
  width: 100%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.group3 input {
  width: 50%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

#preview {
  width: 50%;
}

#preview img {
  width: 100%;
  max-height: 200px;
}

.selection button {
  width: 10%;
  border: none;
  background-color: white;
}

.selection .active {
  width: 10%;
  border: none;
  background-color: #eee;
}

table tbody td input {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

table tbody td select {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

.body4 button {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
}

.option {
  text-align: center;
}

.input-group-text {
  width: 20%;
  border: none;
}
</style>
