<template>
    <div class="page">
        <div class="head-title">
            <div class="left">
                <ul class="breadcrumb">
                    <li>
                        <a href="#">{{ $t("management") }} {{ $t("projects") }}</a>
                    </li>
                    <li>-</li>
                    <li>
                        <a href="#">{{ $t("extracts") }}</a>
                    </li>
                    <li>-</li>
                    <li>
                        <a class="active" href="#">{{ $t("add") }} {{ $t("extract") }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="main">
            <div class="head">
                <div class="head1">
                    <h3>{{ $t("add") }} {{ $t("extract") }}</h3>
                </div>
                <div class="head2">
                    <button class="buttonconfirm" :disabled="disabled" @click.prevent="confirm">
                        {{ $t("save") }}
                    </button>
                </div>

            </div>
            <div class="body">
                <div class="part1">
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">نوع المستخلص
                        </label>
                        <div class="col-sm-2">
                            <select class="form-select form-select-sm" aria-label=".form-select-sm example"
                                v-model="extracttype">
                                <option value="دفعة">دفعة</option>
                                <option value="ضمان">ضمان</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("contractor") }} <span
                                class="error-feedback">{{
                                    "*"
                                }}</span>
                        </label>
                        <div class="col-sm-4">
                            <v-select :options="allcontractors" label="name" v-model="contractor">
                                <template #option="option">
                                    <span>{{ option.name }}</span>
                                </template>
                            </v-select>
                        </div>
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("contracts") }} <span
                                class="error-feedback">{{
                                    "*"
                                }}</span>
                        </label>
                        <div class="col-sm-4">
                            <v-select :options="contracts" label="name" v-model="contract">
                                <template #option="option">
                                    <span>{{ option.name }}</span>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("project") }} <span
                                class="error-feedback">{{
                                    "*"
                                }}</span>
                        </label>
                        <div class="col-sm-4">
                            {{ contract.project }}
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} <span
                                class="error-feedback">{{
                                    "*"
                                }}</span>
                        </label>
                        <div class="col-sm-4">
                            <input type="date" class="form-control form-control-sm" id="inputEmail3" v-model="date" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("amount") }} {{
                            $t("guarantee")
                            }} %
                        </label>
                        <div class="col-sm-1">
                            <input type="number" class="form-control form-control-sm" id="inputEmail3"
                                v-model="guarantee" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        استمارة للاعمال المنجزة للفترة
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("from") }}
                            <span class="error-feedback">{{
                                "*"
                                }}</span></label>

                        <div class="col-sm-2">
                            <input type="date" class="form-control form-control-sm" id="inputEmail3"
                                v-model="startdate" />
                        </div>
                        <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("to") }}
                            <span class="error-feedback">{{
                                "*"
                                }}</span></label>

                        <div class="col-sm-2">
                            <input type="date" class="form-control form-control-sm" id="inputEmail3"
                                v-model="enddate" />
                        </div>
                    </div>
                    <br />
                    <div class="row mb-2">
                        <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("amount") }} {{ $t("contract")
                            }}
                            <span class="error-feedback">{{
                                "*"
                                }}</span></label>
                        <div class="col-sm-3">
                            <span class="amount" v-if="contract.amount">{{ contract.amount }}</span>

                        </div>
                        <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("amount") }} {{ $t("extract")
                            }}
                            <span class="error-feedback">{{
                                "*"
                                }}</span></label>
                        <div class="col-sm-3">
                            <span class="amount" v-if="totalextract">{{ totalextract }}</span>

                        </div>
                        <label for="inputEmail3" class="col-sm-1 col-form-label-sm">اجمالى الضمان
                            <span class="error-feedback">{{
                                "*"
                                }}</span></label>
                        <div class="col-sm-3">
                            <span class="amount" v-if="totalextractgar">{{ totalextractgar }}</span>

                        </div>
                    </div>
                    <hr />
                    <h6>المستخلصات السابقة</h6>
                    <table class="table" style="width: 50%;font-size: small">
                        <thead>
                            <tr>
                                <th>رقم البند</th>
                                <th>{{ $t("product") }}</th>
                                <th>السعر </th>
                                <th>الكمية السابقة</th>
                                <th>المبلغ المدفوع</th>
                                <th>مبلغ الضمان</th>
                                <th>مبلغ الضمان المدفوع</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="x, id in totaltrans" :key="x">
                                <td>{{ id + 1 }}</td>
                                <td>{{ x.name }}</td>
                                <td>{{ x.buyp }}</td>
                                <td>{{ x.paidq }}</td>
                                <td>{{ x.paid }}</td>
                                <td>{{ x.guarantee }}</td>
                                <td><span v-if="x.paidguarantee > 0">{{ x.paidguarantee }}</span></td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    <table class="table" style="width: 100%;font-size: small" v-if="extracttype == 'دفعة'">
                        <thead>
                            <tr>
                                <th>رقم البند</th>
                                <th style="width: 12%">{{ $t("product") }}</th>
                                <th style="width: 12%">{{ $t("classification") }}</th>
                                <th>{{ $t("description") }}</th>
                                <th style="width: 10%">{{ $t("quantity") }}</th>
                                <th style="width: 10%">{{ $t("unit type") }}</th>
                                <th>{{ $t("price") }}</th>
                                <!-- <th>{{ $t("befor") }} {{ $t("tax") }}</th> -->
                                <th>{{ $t("tax") }}</th>
                                <!-- <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                                <th>{{ $t("total") }}</th> -->
                                <th style="width: 10%">{{ $t("quantity") }}</th>
                                <th>مبلغ السداد</th>
                                <th> الضمان</th>
                                <th>الصافي</th>
                                <th>الضريبة</th>
                                <th>{{ $t("total") }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="x, id in contdata" :key="x">
                                <td>{{ id + 1 }}</td>
                                <td>{{ x.pronamear }}</td>
                                <td>{{ x.classname }}</td>
                                <td>
                                    {{ x.description }}
                                </td>
                                <td>
                                    {{ x.quantity }}
                                </td>
                                <td>{{ x.unittype }}</td>
                                <td>{{ x.buyp }}</td>
                                <!-- <td>{{ x.tbt }}</td> -->
                                <td>{{ x.tax }} %</td>
                                <!-- <td>{{ x.taxamount }}</td>
                                <td>{{ x.total }}</td> -->
                                <td style="width: 5%;"><input type="number" v-model="x.paidq" style="width: 100%;"
                                        @change="caltax(id)" />
                                </td>
                                <td style="width: 5%;">{{ x.paid }}
                                </td>
                                <td style="width: 5%;">
                                    <input type="number" v-model="x.guarantee" @change="calgar(id)" />
                                </td>
                                <td style="width: 5%;">{{ x.paidgar }}
                                </td>
                                <td style="width: 5%;">{{ x.taxamountpaid }}
                                </td>
                                <td>{{ x.total }}</td>
                                <td><a href="#" @click.prevent="del(id)"><i class="bi bi-x-circle-fill"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table" style="width: 100%;font-size: small" v-if="extracttype == 'ضمان'">
                        <thead>
                            <tr>
                                <th>رقم البند</th>
                                <th style="width: 12%">{{ $t("product") }}</th>
                                <th style="width: 12%">{{ $t("classification") }}</th>
                                <th>{{ $t("description") }}</th>
                                <th style="width: 10%">{{ $t("quantity") }}</th>
                                <th style="width: 10%">{{ $t("unit type") }}</th>
                                <th>{{ $t("price") }}</th>
                                <th>{{ $t("tax") }}</th>
                                <th>سداد الضمان</th>
                                <th>الضريبة</th>
                                <th>الاجمالى</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="x, id in contdata" :key="x">
                                <td>{{ id + 1 }}</td>
                                <td>{{ x.pronamear }}</td>
                                <td>{{ x.classname }}</td>
                                <td>
                                    {{ x.description }}
                                </td>
                                <td>
                                    {{ x.quantity }}
                                </td>
                                <td>{{ x.unittype }}</td>
                                <td>{{ x.buyp }}</td>
                                <td>{{ x.tax }} %</td>
                                <td style="width: 5%;"><input type="number" v-model="x.paidguarantee"
                                        style="width: 100%;" @change="caltax2(id)" />
                                </td>
                                <td>{{ x.taxpaidgar }}</td>
                                <td>{{ x.tpaidgar }}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
export default {
    components: {
        vSelect,
    },
    async mounted() {
        const result1 = await axios.get("/projects/allcontractors");
        // const result4 = await axios.get("/data/allprojects");

        if (result1.status == 200) {
            // this.allprojects = result4.data.data;
            this.allcontractors = result1.data.data
        }
    },
    props: ["css"],
    data() {
        return {
            disabled: false,
            v$: useValidate(),
            name: "",
            allprojects: [],
            allcontractors: [],
            contdata: [],
            project: "",
            contdate: '',
            startdate: "",
            enddate: "",
            amount: "",
            contractor: "",
            contracts: [],
            contract: "",
            totalextract: "",
            date: "",
            totaltrans: [],
            guarantee: 0,
            guaranteeamount: "",
            totalextractgar: "",
            extracttype: "دفعة"
        };
    },
    validations() {
        return {
            contract: { required },
            date: { required }
        };
    },
    methods: {
        async del(id) {
            this.contdata.splice(id, 1);
            this.totalextract = this.contdata.reduce(
                (acc, array) => acc + Number(array.paid),
                0
            );
        },
        caltax(id) {
            if (this.contdata[id].tax > 0) {
                const amount = this.contdata[id].paidq * this.contdata[id].buyp
                this.contdata[id].paid = Number(amount).toFixed(2)
                this.contdata[id].guarantee = amount * this.guarantee / 100,
                    this.contdata[id].paidgar = amount - this.contdata[id].guarantee
                this.contdata[id].taxamountpaid = Number(this.contdata[id].paidgar * this.contdata[id].tax / 100).toFixed(2)
                this.contdata[id].total = Number(Number(this.contdata[id].paidgar) + Number(this.contdata[id].taxamountpaid)).toFixed(2)
            } else {
                const amount = this.contdata[id].paidq * this.contdata[id].buyp
                this.contdata[id].paid = Number(amount).toFixed(2)
                this.contdata[id].taxamountpaid = 0
                this.contdata[id].guarantee = amount * this.guarantee / 100,
                    this.contdata[id].paidgar = amount - this.contdata[id].guarantee
                this.contdata[id].total = Number(Number(this.contdata[id].paidgar) + Number(this.contdata[id].taxamountpaid)).toFixed(2)
            }
            this.totalextract = this.contdata.reduce(
                (acc, array) => acc + Number(array.total),
                0
            );
            this.totalextractgar = this.contdata.reduce(
                (acc, array) => acc + Number(array.guarantee),
                0
            );
            this.guaranteeamount = this.totalextract * this.guarantee / 100
        },
        caltax2(id) {
            if (this.contdata[id].tax > 0) {
                this.contdata[id].taxpaidgar = Number(this.contdata[id].paidguarantee * this.contdata[id].tax / 100)
                this.contdata[id].tpaidgar = Number(this.contdata[id].paidguarantee) + Number(this.contdata[id].paidguarantee * this.contdata[id].tax / 100)
            } else {
                this.contdata[id].taxpaidgar = 0
                this.contdata[id].tpaidgar = Number(this.contdata[id].paidguarantee)
            }
            this.totalextract = this.contdata.reduce(
                (acc, array) => acc + Number(array.paidguarantee),
                0
            );
            this.totalextractgar = this.contdata.reduce(
                (acc, array) => acc + Number(array.paidguarantee),
                0
            );
        },
        calgar(id) {
            if (this.contdata[id].tax > 0) {
                const amount = this.contdata[id].paidq * this.contdata[id].buyp
                this.contdata[id].paid = Number(amount).toFixed(2)
                this.contdata[id].paidgar = amount - this.contdata[id].guarantee
                this.contdata[id].taxamountpaid = Number(this.contdata[id].paidgar * this.contdata[id].tax / 100).toFixed(2)
                this.contdata[id].total = Number(Number(this.contdata[id].paidgar) + Number(this.contdata[id].taxamountpaid)).toFixed(2)
            } else {
                const amount = this.contdata[id].paidq * this.contdata[id].buyp
                this.contdata[id].paid = Number(amount).toFixed(2)
                this.contdata[id].taxamountpaid = 0
                this.contdata[id].paidgar = amount - this.contdata[id].guarantee
                this.contdata[id].total = Number(Number(this.contdata[id].paidgar) + Number(this.contdata[id].taxamountpaid)).toFixed(2)
            }
            this.totalextract = this.contdata.reduce(
                (acc, array) => acc + Number(array.total),
                0
            );
            this.totalextractgar = this.contdata.reduce(
                (acc, array) => acc + Number(array.guarantee),
                0
            );
            this.guaranteeamount = this.totalextract * this.guarantee / 100
        },
        async confirm() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.disabled = true;
                const result = await axios.post(`/projects/createnewcontractorextract`, {
                    username: store.state.randp.username,
                    name: this.contract.name,
                    contract: this.contract.name,
                    contractid: this.contract.cid,
                    project: this.contract.project,
                    projectid: this.contract.projectid,
                    startdate: this.startdate,
                    enddate: this.enddate,
                    amount: this.totalextract,
                    contractorname: this.contractor.name,
                    contractorid: this.contractor.cid,
                    contdata: JSON.stringify(this.contdata),
                    date: this.date,
                    old: JSON.stringify(this.totaltrans),
                    guarantee: this.guarantee,
                    guaranteeamount: this.totalextractgar,
                    amountwout: this.totalextract,
                    type: this.extracttype
                });
                if (result.status == 200) {
                    this.redirectTo({ val: "AllExtracts" });
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                        },
                    });

                    Toast.fire({
                        icon: "success",
                        title: "Data Entry successfully",
                    });
                    // this.redirectTo({ val: "Home" });
                } else if (result.status == 401) {
                    alert("Account Already Created Before");
                }
            }
        },
        ...mapActions(["redirectTo"]),
    },
    watch: {
        extracttype() {
            this.contract = {}
            this.contdata = {}
            this.totaltrans = {}
        },
        async contractor() {
            const result1 = await axios.get(`/projects/getconstractorcontract/${this.contractor.cid}`);
            if (result1.status == 200) {
                this.contracts = result1.data.data
                this.contract = {}
                this.contdata = {}
                this.totaltrans = {}
            }
        },
        async contract() {
            this.guarantee = this.contract.guarantee
            this.contdata = JSON.parse(this.contract.contdata)
            const result1 = await axios.get(`/projects/getconstractorcontracttran/${this.contract.cid}`);
            if (result1.status == 200) {
                const trans = result1.data.data
                this.alltrans = []
                this.totaltrans = []
                if (trans.length > 0) {
                    for (let i = 0; i < trans.length; i++) {
                        const element = trans[i];
                        const data = JSON.parse(element.contdata)
                        this.alltrans = [...this.alltrans, ...data]
                    }
                }

                for (let x = 0; x < this.contdata.length; x++) {
                    const element = this.contdata[x];
                    const data = this.alltrans.filter((x) => x.sn == element.sn && x.buyp == element.buyp);
                    for (let x = 0; x < data.length; x++) {
                        const element = data[x];
                        if (!element.tpaidgar) {

                            element.paidguarantee = 0
                            element.taxpaidgar = 0
                            element.tpaidgar = 0
                        }
                    }
                    const totalpaid = data.reduce(
                        (acc, array) => acc + Number(array.total),
                        0
                    );
                    const totalpaidq = data.reduce(
                        (acc, array) => acc + Number(array.paidq),
                        0
                    );
                    const totalgar = data.reduce(
                        (acc, array) => acc + Number(array.guarantee),
                        0
                    );
                    const totalgarpaid = data.reduce(
                        (acc, array) => acc + Number(array.tpaidgar),
                        0
                    );
                    this.totaltrans.push({
                        name: element.pronamear,
                        sn: element.sn,
                        paid: totalpaid,
                        paidq: totalpaidq,
                        buyp: element.buyp,
                        guarantee: totalgar,
                        paidguarantee: totalgarpaid
                    })
                }
                for (let x = 0; x < this.contdata.length; x++) {
                    const element = this.contdata[x];
                    element.paidq = 0
                    element.paid = 0
                    element.taxamountpaid = 0
                    element.paidwithouttax = 0
                    element.guarantee = 0
                    element.paidgar = 0
                    element.total = 0
                    element.paidguarantee = 0
                    element.taxpaidgar = 0
                    element.tpaidgar = 0
                }
                this.totalextractgar = 0
            }
        },
        guarantee() {
            this.guaranteeamount = this.totalextract * this.guarantee / 100
        }
    },
};
</script>
<style lang="scss" scoped>
.page {
    direction: v-bind("css.EN");
    padding: 0.5%;
}

.main {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
}

.body {
    padding: 1%;
    // min-height: 500px;
    // border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part {
    font-size: large;
    width: 99%;
    padding: 2%;
    margin: 0.5%;
    background-color: white;
    border-radius: 5px;
    color: black;
    display: flex;
    // border: 1px solid black;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part .group {
    width: 50%;
    //   border: black 1px solid;
}

.part img {
    width: 100%;
    height: 100px;
    //   border: black 1px solid;
}

ul {
    text-decoration: none;
    list-style-type: none;
    color: black;
}

.bi {
    margin: 1%;
}

.head {
    padding: 0.25%;
    display: flex;
    border-bottom-style: ridge;
}

.head1 {
    width: 60%;
}

.head1 input {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
}

.head2 {
    width: 40%;
    text-align: center;
}

.head2 button {
    margin: 0.5%;
    padding: 1%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
    font-size: small;
    width: 30%;
}

.head2 button a {
    color: white;
}

.amount {
    background-color: gray;
    color: white;
    padding: 2%;
}
</style>