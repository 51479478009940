<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/purchases/AllProducts" class="active">{{
              $t("products")
            }}</router-link>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("add") }} {{ $t("product") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("add") }} {{ $t("product") }}</h3>
        </div>
        <div class="head2">
          <button @click="submit">{{ $t("save") }}</button>
        </div>
      </div>
      <div class="body">
        <br />
        <div class="form1">
          <h5>{{ $t("details") }}</h5>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("name") }} {{ $t("ar") }}
              <span class="error-feedback" v-if="v$.namear.$error">{{
                "*"
              }}</span></label>
            <div class="col-sm-4">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="namear" />
            </div>
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("name") }} {{ $t("en") }}
              <span class="error-feedback" v-if="v$.nameen.$error">{{
                "*"
              }}</span></label>

            <div class="col-sm-4">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="nameen" />
            </div>
          </div>
          <!--  -->
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("sn") }}
              <span class="error-feedback" v-if="v$.sn.$error">{{
                "*"
              }}</span></label>

            <div class="col-sm-4">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="sn" />
            </div>
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("item") }}
              <span class="error-feedback" v-if="v$.item.$error">{{
                "*"
              }}</span></label>

            <div class="col-sm-3">
              <v-select :options="data1" label="category" v-model="item">
                <template #option="option">
                  <span>{{ option.category }}</span>
                </template>
              </v-select>
            </div>
            <div class="col-sm-1">
              <button class="add" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                {{ $t("add") }} {{ $t("item") }}
              </button>
            </div>
          </div>
          <!--  -->
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">
              {{ $t("unit type") }}
              <span class="error-feedback" v-if="v$.unittype.$error">{{
                "*"
              }}</span>
            </label>

            <div class="col-sm-4">
              <v-select :options="unit" label="unit" v-model="unittype">
                <template #option="option">
                  <span style="text-align: center;">{{ option.unit }}</span>
                </template>
              </v-select>
            </div>
          </div>
          <div class="row mb-1">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">
              {{ $t("type") }} {{ $t("product") }}
              <span class="error-feedback" v-if="v$.unittype.$error">{{
                "*"
              }}</span>
            </label>

            <div class="col-sm-10">
              <select name="" id="" style="width: 40%;" v-model="protype">
                <option value="1">{{ $t("prodtype1") }}</option>
                <option value="2">{{ $t("prodtype2") }}</option>
                <option value="3">{{ $t("prodtype3") }}</option>
              </select>
            </div>
          </div>
          <div v-if="protype == 2">
            <hr />
            <div class="prodetails">
              {{ $t("details") }} {{ $t("aggregation") }} {{ $t("product") }}
              <br /><br />
              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-1 col-form-label-sm">
                  {{ $t("product") }} :
                </label>

                <div class="col-sm-10">
                  <select type="text" class="form-control form-control-sm" id="inputEmail3" v-model="rawmaterialselect">
                    <option v-for="val in rawmaterial" :key="val" :value="val">
                      {{ val.namear }}
                    </option>
                  </select>

                  <button class="buttonconfirm" @click="add" style="padding: 0.25%">
                    {{ $t("add") }} <i class="bi bi-plus"></i>
                  </button>
                </div>
              </div>

              <table class="table">
                <thead>
                  <tr>
                    <td>{{ $t("product") }}</td>
                    <td>{{ $t("unit type") }}</td>
                    <td>{{ $t("quantity") }}</td>
                    <td>{{ $t("price") }}</td>
                    <td>{{ $t("total") }}</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="val in pddata" :key="val">
                    <td>{{ val.namear }}</td>
                    <td>{{ val.unittype }}</td>
                    <td>
                      <input class="form-control form-control-sm" v-model="val.pquon" @change="total" />
                    </td>
                    <td><input class="form-control form-control-sm" v-model="val.pprice" @change="total" /></td>
                    <td>{{ val.pprice * val.pquon }}</td>
                    <td>
                      <button class="buttonconfirm" @click="remove(val.id)" style="padding: 0">
                        <i class="bi bi-dash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{ $t("total") }}</td>
                    <td>{{ rawmaterialcost }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <!--  -->
          <hr />
          <h5>{{ $t("accounting") }}</h5>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("follow") }} {{ $t("stock") }}
            </label>

            <div class="col-sm-1">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="stock" />
            </div>
          </div>
          <!--  -->
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("tax") }} %
              <span class="error-feedback" v-if="v$.tax.$error">{{
                "*"
              }}</span></label>
            <div class="col-sm-2">
              <input type="number" class="form-control form-control-sm" id="inputEmail3" v-model="tax" />
            </div>
          </div>
          <!--  -->
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("saleprice") }}
              <span class="error-feedback" v-if="v$.salep.$error">{{
                "*"
              }}</span></label>

            <div class="col-sm-1">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="salep" />
            </div>
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("Account") }} {{ $t("sales") }}
              <span class="error-feedback" v-if="v$.salec.$error">{{
                "*"
              }}</span>
            </label>

            <div class="col-sm-3">
              <v-select :options="buylist" label="namear" v-model="salec">
                <template #option="option">
                  <span>{{ option.namear }}</span>
                </template>
              </v-select>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("buyprice") }}
              <span class="error-feedback" v-if="v$.buyp.$error">{{
                "*"
              }}</span></label>

            <div class="col-sm-1">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="buyp" />
            </div>
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("Account") }} {{ $t("cost") }}
              <span class="error-feedback" v-if="v$.buyc.$error">{{
                "*"
              }}</span></label>

            <div class="col-sm-3">
              <v-select :options="selllist" label="namear" v-model="buyc">
                <template #option="option">
                  <span>{{ option.namear }}</span>
                </template>
              </v-select>
            </div>

          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("sale") }} {{ $t("discount") }}
              <span class="error-feedback" v-if="v$.discount.$error">{{
                "*"
              }}</span></label>

            <div class="col-sm-1">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="discount" />
            </div>
          </div>
          <!--  -->

          <!--  -->
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("image") }} {{ $t("product") }}
            </label>

            <div class="col-sm-5">
              <input type="file" class="form-control form-control-sm" id="image" :name="this.sn"
                @change="onFileChange" />
            </div>
            <div id="preview">
              <img v-if="url" :src="url" />
            </div>
          </div>
          <!--  -->
        </div>
      </div>
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <h4>{{ $t("add") }} {{ $t("item") }}</h4>
              <div class="row mb-4">
                <label for="inputEmail3" class="col-sm-3 col-form-label-sm">{{ $t("name") }} {{ $t("categorie") }}
                </label>
                <div class="col-sm-5">
                  <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="catname"
                    @change="checkname()" />
                  <span class="error-feedback" v-if="alerted == true">{{
                    "تم تسجيل المنتج من قبل"
                  }}</span>
                </div>
              </div>
              <div class="row mb-2">
                <label for="inputEmail3" class="col-sm-3 col-form-label-sm">{{ $t("description") }}
                </label>
                <div class="col-sm-5">
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                    v-model="catdescription"></textarea>
                </div>
              </div>
              <!-- <div class="row mb-">
                <label for="inputEmail3" class="col-sm-3 col-form-label-sm"
                  >{{ $t("categorie") }} {{ $t("parent") }}
                </label>
                <div class="col-sm-5">
                  <v-select :options="data1" label="category" v-model="catitem">
                    <template #option="option">
                      <span>{{ option.category }}</span>
                    </template>
                  </v-select>
                </div>
              </div> -->
            </div>

            <div class="modal-footer">
              <button @click="submitcat" class="buttonconfirm">
                {{ $t("save") }}
              </button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions } from "vuex";
import html2pdf from "html2pdf.js";
import vSelect from "vue-select";
export default {
  props: ["css"],
  components: {
    vSelect,
  },
  async mounted() {
    const result = await axios.get("/data/allproductcategoriesparent");
    const result2 = await axios.get("/data/allunittypes");
    const result3 = await axios.get("/data/accountingmanual");
    const result4 = await axios.get("/data/allproducts3");
    const result5 = await axios.get("/data/lastproductsn");
    if (result.status == 200) {
      this.data1 = result.data.data;
      this.data2 = result.data.data;
      this.unit = result2.data.data;
      this.selllist = result3.data.data;
      this.buylist = result3.data.data;
      this.rawmaterial = result4.data.data;
      this.sn = Number(result5.data.data[0].sn) + 1
    }
    if (store.state.cnum[0].cnum == '116') {
      this.buyc = { "id": 188, "namear": "مشروعات تحت التنفيذ ", "nameen": "مشروعات تحت التنفيذ ", "typeact": "debit", "actnum": "120402" }
      this.salec = { "id": 247, "namear": "إيرادات إدارة مشاريع", "nameen": "إيرادات إدارة مشاريع", "typeact": "credit", "actnum": "4601" }
    }

  },
  data() {
    return {
      disabled: false,
      v$: useValidate(),
      namear: "",
      nameen: "",
      sn: "",
      item: "",
      description: "",
      unittype: "",
      tax: "",
      salep: 0,
      salec: "",
      buyp: 0,
      buyc: "",
      discount: 0,
      stock: false,
      unitsel: "",
      mobile: "",
      code: "",
      barcode: 0,
      url: null,
      pddata: [],
      pname: "",
      pquon: "",
      pprice: "",
      data1: [],
      data2: [],
      unit: [],
      filter: "",
      selection: false,
      alert: false,
      selllist: [],
      buylist: [],
      rawmaterial: [],
      rawmaterialselect: "",
      rawmaterialcost: 0,
      file: "",
      alerted: false,
      catname: "",
      catdescription: "",
      catitem: "",
      protype: 1
    };
  },
  validations() {
    return {
      namear: { required },
      nameen: { required },
      sn: { required },
      item: { required },
      unittype: { required },
      barcode: { required },
      tax: { required },
      salep: { required },
      salec: { required },
      buyp: { required },
      buyc: { required },
      discount: { required },
    };
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/AllProducts/NewProduct",
      });
    },
    async checkname() {
      const result = await axios.get(`/data/productcheckname/${this.catname}`);
      if (result.status == 200) {
        const data = result.data.data;
        if (data.length > 0) {
          this.alert = true;
        } else {
          this.alert = false;
        }
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error && this.alert == false) {
        this.disabled = true;
        const result = await axios.post("/data/createnewproduct", {
          username: store.state.randp.username,
          kind: this.protype,
          namear: this.namear,
          nameen: this.nameen,
          sn: this.sn,
          item: this.item.category,
          description: this.description,
          unittype: this.unittype.unit,
          barcode: this.sn,
          tax: this.tax,
          salep: this.salep,
          salec: this.salec.actnum,
          snamear: this.salec.namear,
          snameen: this.salec.nameen,
          buyp: this.buyp,
          buyc: this.buyc.actnum,
          bnamear: this.buyc.namear,
          bnameen: this.buyc.nameen,
          discount: this.discount,
          stock: this.stock,
          rawmaterial: JSON.stringify(this.pddata),
          rawmaterialcost: this.rawmaterialcost,
        });
        if (result.status == 200) {
          let formData = new FormData();
          formData.append("file", this.file);
          axios.patch(`/upload/${this.sn}`, formData);
          this.redirectTo({ val: "AllProducts" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    add() {
      const id = this.rawmaterialselect.id;
      const filtter = this.pddata.filter((x) => x.id == id);
      if (filtter.length == 0) {
        this.pddata.push({
          id: this.rawmaterialselect.id,
          namear: this.rawmaterialselect.namear,
          nameen: this.rawmaterialselect.nameen,
          sn: this.rawmaterialselect.sn,
          unittype: this.rawmaterialselect.unittype,
          pquon: 1,
          pprice: this.rawmaterialselect.buyp,
        });
        this.rawmaterialcost = 0;
        for (let i = 0; i < this.pddata.length; i++) {
          this.rawmaterialcost =
            this.pddata[i].pquon * this.pddata[i].pprice + this.rawmaterialcost;
        }
      } else {
        alert("تم اضافة المنتج ");
      }
    },
    remove(id) {
      const indexOfObject = this.pddata.findIndex((object) => {
        return object.id === id;
      });
      this.pddata.splice(indexOfObject, 1);
      this.rawmaterialcost = 0;
      for (let i = 0; i < this.pddata.length; i++) {
        this.rawmaterialcost =
          this.pddata[i].pquon * this.pddata[i].pprice + this.rawmaterialcost;
      }
    },
    total() {
      this.rawmaterialcost = 0;
      for (let i = 0; i < this.pddata.length; i++) {
        this.rawmaterialcost =
          this.pddata[i].pquon * this.pddata[i].pprice + this.rawmaterialcost;
      }
    },
    print() {
      html2pdf(document.getElementById("barcode"), {
        margin: 0,
        filename: "barcode.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      });
    },
    async submitcat() {
      const result = await axios.post("/data/createnewproductcategory", {
        username: store.state.randp.username,
        category: this.catname,
        description: this.catdescription,
        parent: this.catitem.category,
      });
      if (result.status == 200) {
        const result5 = await axios.get("/data/allproductcategoriesparent");
        if (result.status == 200) {
          this.data1 = result5.data.data;
        }
        this.item = { category: this.catname };
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Data Entry successfully",
        });
        // this.redirectTo({ val: "Home" });
      } else if (result.status == 401) {
        alert("Account Already Created Before");
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    filter() {
      this.data1 = this.data2.filter((x) =>
        x.category.includes(this.filter.toLowerCase())
      );
    },
    rawmaterialcost() {
      this.buyp = this.rawmaterialcost;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 2%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
